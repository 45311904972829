import './static/vendor/bootstrap/css/bootstrap.min.css'
import "./static/vendor/bootstrap-icons/bootstrap-icons.css"
import './static/vendor/boxicons/css/boxicons.min.css'

import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans/300-italic.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/400-italic.css"
import "@fontsource/open-sans/500.css"
import "@fontsource/open-sans/500-italic.css"
import "@fontsource/open-sans/600.css"
import "@fontsource/open-sans/600-italic.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/open-sans/700-italic.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/300-italic.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/400-italic.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/500-italic.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/700-italic.css"
import "@fontsource/poppins/300.css"
import "@fontsource/poppins/300-italic.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/500-italic.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/600-italic.css"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/700-italic.css"

import "./static/styles/style.css"

export const onInitialClientRender = () => {
    setTimeout(function () {
        // document.getElementById("___loader").style.display = "none"
    }, 1000)
}
